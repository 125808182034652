import type { ForwardedRef } from "react";

export const mergeRefs =
  <T extends HTMLElement>(...refs: ForwardedRef<T>[]) =>
  (instance: T) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref != null) {
        ref.current = instance;
      }
    });
  };
