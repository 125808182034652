import { useIntersectionObserver, useMountEffect } from "@react-hookz/web";
import {
  type ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useRef,
} from "react";

import { mergeRefs } from "~/utils/merge-refs";

type Props = Pick<
  ComponentPropsWithRef<"video">,
  "children" | "className" | "height" | "poster" | "src" | "style" | "width"
>;

export const GradientVideo = forwardRef<HTMLVideoElement, Props>(
  function GradientVideoWithForwardedRef(
    { className, ...restProps },
    forwardedRef,
  ) {
    const internalRef = useRef<HTMLVideoElement>(null);
    const { isIntersecting = false } =
      useIntersectionObserver(internalRef) ?? {};

    const ref = mergeRefs(forwardedRef, internalRef);

    useEffect(() => {
      if (isIntersecting) void internalRef.current?.play();
      else internalRef.current?.pause();
    }, [isIntersecting]);

    useMountEffect(() => {
      if (internalRef.current != null) internalRef.current.playbackRate = 0.5;
    });

    return (
      <video
        autoPlay={false}
        className={className}
        controls={false}
        loop
        muted
        playsInline
        ref={ref}
        {...restProps}
      />
    );
  },
);
